//export const FormatDateExtern = "dd/MM/yyyy hh:mm"; //unisabana
export const FormatDateExtern = "yyyy-MM-dd hh:mm:ssS"; //Usta 20230419    235900
export const baseUrl = 'https://bkappustabog.geniatecnologia.co:5000/';
// export const baseUrl = 'https://appbiblioteca.unisabana.edu.co:5000/'; //Production SSL unisabana
//export const baseUrl = 'https://bibloredapp.geniatecnologia.co:5000/'; //Production SSL biblored
// export const baseUrl = 'http://52.14.65.118:4000/'; //Production
// export const baseUrl = 'http://52.42.94.80:4000/'; //Test

export const sessionEndpoint = () => `${baseUrl}sessions`;
export const sessionAppTypeEndpoint = () => `${baseUrl}sessions/modules`;
// =======================================================
/// Reports
// =======================================================
export const generalReportsLoanEndpoint = (params, monthly) => {
  if (monthly) {
    return `${baseUrl}general_report_loans/monthly${serialize(params)}`;
  } else {
    return `${baseUrl}general_report_loans/${serialize(params)}`;
  }
};

export const generalReportsTutorsEndpoint = (params) => 
  `${baseUrl}reservations/general_report${serialize(params)}`;

export const generalReportsRoomsEndpoint = (params) =>
  `${baseUrl}reservations/general_report${serialize(params)}`;

export const generalReportsEndpoint = (params) =>
  `${baseUrl}reservations/index_with_department/${serialize(params)}`;

// =======================================================
/// Users
// =======================================================
export const userTracesEndpoint = params => `${baseUrl}user_traces/${serialize(params)}`;
export const getSystemUsersEndpoint = () => `${baseUrl}users`;
export const getSystemUserProfileEndpoint = () => `${baseUrl}users/me`;
export const updateSystemUserProfileEndpoint = () => `${baseUrl}users/me`;
export const addSystemUsersEndpoint = () => `${baseUrl}users`;
export const removeSystemUsersEndpoint = (id) => `${baseUrl}users/${id}`;

// =======================================================
/// Rooms
// =======================================================
export const getRoomEndpoint = () => `${baseUrl}rooms`;
export const getRoomReservationsEndpoint = (day) => `${baseUrl}reservations/status_by_day/?day=${day}&type=room`;
export const getRoomsEndpoint = () => `${baseUrl}rooms`;
export const addRoomEndpoint = () => `${baseUrl}rooms`;
export const editRoomEndpoint = (id) => `${baseUrl}rooms/${id}`;


export const removeReservationsEndpoint = (id) => `${baseUrl}reservations/${id}`;
// export const removeReservationsEndpoint = (id) => `http://52.14.65.118:4000/reservations/${id}`;

// =======================================================
/// Tutor
// =======================================================
export const getTutorEndpoint = () => `${baseUrl}tutors`;
export const getTutorsEndpoint = () => `${baseUrl}tutors`;
export const getTutorsReservationsEndpoint = (day) => `${baseUrl}reservations/status_by_day/?day=${day}&type=tutor`;
export const addTutorEndpoint = () => `${baseUrl}tutors`;
export const editTutorEndpoint = (id) => `${baseUrl}tutors/${id}`;

export const getThemesEndpoint = () => `${baseUrl}themes`;

export const addScheduleEndpoint = () => `${baseUrl}schedules`;
export const addListScheduleEndpoint = () => `${baseUrl}schedules/bulk_create`;

function serialize(obj) {
  return ('?' + Object.keys(obj).reduce((a, k) => {
    a.push(k + '=' + encodeURIComponent(obj[k]));
    return a;
  }, []).join('&'));
}

